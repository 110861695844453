<template>
  <div :class="{mobile:isMobile}">
    <div class="title d-flex flex-row">
      <div class="purchase" :class="{btn:purchaseClick}"  @click="model_clicked('Purchase')">{{ $t("purchase_order") }}</div>
      <div class="purchase ml-lg-12 ml-4"  :class="{btn:saleClick}" @click="model_clicked('sale')">{{ $t("sales_order") }}</div>
    </div>
    <div class="line"></div>
      <div class="group" ref="group" @scroll="groupScroll" @mouseover="groupMouseover">
         <div class="item" v-for="(item,index) in orders" :key="index">
            <img class="img" :src="item.imgUrl"/>
            <div class="name" style="width:20%">
               <span class="s1 contentleft">{{item.name}}</span>
                <v-card-text class="d-flex flex-row  pb-2 px-5 pt-2 pl-0 text-avatar" v-if="saleMode == 'Purchase'">
                <Avatar  size="25" :did="item.contractOwner" :hash= "item.issuerImg" 
				:grade="item.grade"
				 imgType="smallv"
				showAvatarInfo/>
               <div class="ml-2 text-truncate" style="max-width: 100%;">{{item.issuerName ?item.issuerName :item.owner.substr(0,6)+"..."+item.owner.substr(-6)}}</div>
               </v-card-text>
               <!-- <span class="s3 contentleft" v-if="saleMode == 'Purchase'">{{timestampToDateTime(item.issuesTime)}}</span> -->
            </div>
            <div class="price " style="width:20%">
               <span class="s1">{{ $t("popupBuyPricePrice") }}</span>
               <div class="d-flex flex-row ml-n3">
                      <v-img :src="
                require('@/assets/icons/' +
                  getChainByName(item.coinDenom)+
                  '.png')
          " width="22px" height="22px" class="img" />
                

               <span class="s2">{{item.payPrice}}</span>

               </div>
               
            </div>
            <div class="time" style="width:30%">
               <span class="s1">{{ $t("popupBuyPriceOrderTime") }}</span>
               <span class="s2">{{timestampToDateTime(item.createTimeLong)}}</span>
            </div>
            <div class="state ml-lg-n8" style="width:30%;text-align:right" v-if="saleMode == 'Purchase'">
               <div v-if="item.status==5">
                  <div class="box ml-lg-16">
                     <v-btn class="s1" @click="cancelBtn(item.no)">{{ $t("orderActionCancel") }}</v-btn>
                  </div>
                  <v-btn class="s2" @click="payBtn(item.no,item.name)">{{ $t("orderActionPay") }}</v-btn>
               </div>
               <div v-else-if="item.status==0">
                  <span class="s3">{{ $t("orderStateCancel") }}</span>
               </div>
               <div v-else-if="item.status==20">
                  <span class="s3">{{ $t("orderStateCancel") }}</span>
               </div>
               <div v-else-if="item.status==21">
                  <span class="s3">{{ $t("orderStateSuccess") }}</span>
               </div>
               <div v-else-if="item.status==22">
                  <span class="s3">{{ $t("orderStateFail") }}</span>
               </div>
               <div v-else-if="item.status==1">
                  <!-- 进行中 -->
                  <span class="s3">{{ $t("workCardInprogress") }}</span>
               </div>

            </div>
            <div class="saleStates  d-flex flex-column" style="width:30%;" v-else>
                <div class="titles">{{ $t("purchaser") }}</div>
                <div class="d-flex flex-row">
                  <Avatar size="41" :did="item.toOwner" :grade="item.grade"
				        imgType="middlev" :hash="item.targetOwnerPhoto" showAvatarInfo/>
                    <div class="ml-2 mt-2 text-truncate" style="max-width: 100%;">{{ item.targetOwnerName ?  item.targetOwnerName:item.toOwner.substr(0, 6) + "..." + item.toOwner.substr(-6) }}</div>
                </div>
                 

            </div>
         </div>
         <loading :isShowLoading="isShowLoading" ></loading>
      </div>
      <uComponents  ref="ucom"></uComponents>
   </div>
</template>

<script>
import Avatar from '@/components/avatar/index.vue';
  import api from "@/api";
  import { orderPay,quiryTx } from "../../keplr/iris/wallet";
  import { getFileSrc } from "@/utils/file";
   import Loading from "@/components/loading.vue";

  const AVATAR_KEY = "AVATAR";

  export default {
     components: { Loading,Avatar},
    data() {
      return {
        pageNumber: 0,
        pageSize: 10,
        totalPage: 0,

        orders: [],
         isShowLoading: false,
        flag:true,
         saleClick:false,
        purchaseClick:true,
         saleMode:'Purchase',
              isloadList:false,
      };
    },
    mounted() {
      this.getData(this.saleMode);
    },
     computed: {
		isMobile: function () {
			return this.$vuetify.breakpoint.mobile;
		}
	},

    methods: {
          sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
        model_clicked(model){
          debugger
        this.orders = []
        this.pageNumber = 0
      this.saleMode = model
      if(model == 'Purchase'){
           this.purchaseClick = true
      this.saleClick = false
      }else{
         this.purchaseClick = false
      this.saleClick = true
      }
      this.getData(model);
    },
      async getData(model) {
         console.log(22343,this.pageNumber);
        this.pageNumber++;
          this.isShowLoading = true;
        let params = {
          owner: this.$store.state.did,
          chainType: this.$store.state.chainType,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize
        };
        if(model == 'sale'){
        params.transactionType =2
      }else{
         params.transactionType =1
      }
        console.log(22, params);
            if( this.isloadList){
        return
      }
      this.isloadList = true
        let res = await api.home.transactions(params);
        this.totalPage = res.data.totalPage;
        this.getImage(res.data.list, "imgUrl");
         if(this.pageNumber == 1)
        {
           this.orders=[]
        }
        this.orders = [...this.orders, ...res.data.list];
       

         this.isShowLoading = false;
           this.isloadList = false
       
      },
      async getImage(list, field) {
        list.forEach(async v => {
          let src = await getFileSrc(AVATAR_KEY, v[field]);
          v[field] = src;
        });
      },
      async cancelBtn(order_No) {
        console.log("cancelBtn ");

        console.log(order_No);
        let res = await api.home.orderCancel({
          orderNo: order_No
        });
        if (res != null && res.success) {
          this.pageNumber = 0;
          this.orders = [];
          this.getData(this.saleMode);
        }

      },
      async payBtn(order_No,Ordername) {

         try {
              console.log("payBtn " + order_No + Ordername);

debugger
        let preOrderRes = await api.home.orderByOrderNo({
          "orderNo": order_No
        });
        if (!preOrderRes.data || !preOrderRes.data.tokenId ) {
            this.$toast("error",preOrderRes.msg );
          return;
        }
        console.log(preOrderRes);
        let orderNo = preOrderRes.data.orderNo;
      
        let memo = "购买NFT[" + preOrderRes.data.name + "]";

        let assetLevel = preOrderRes.data.assetLevel;
        let orderPrice = "";


        if (preOrderRes.success != "" && preOrderRes.success == true) {
			if(preOrderRes.data.paymentAmount!=null){
				 orderPrice = preOrderRes.data.paymentAmount;
			}else{
				 orderPrice = preOrderRes.data.orderPrice;
			}
         


        }

        // get fee
        let res = await api.home.getFee();
        console.log("res ---");
        console.log(res);
		let recipient = res.data.irisMerchantAddress;
        let IrisPlatformMallFee = res.data.IrisPlatformMallFee;
        let IrisCreatorMallFee = 0;
        let IrisAddress = res.data.IrisAddress;
        if (!res.data.IrisCreatorMallFee) {
          IrisCreatorMallFee = 0;

        } else {
          IrisCreatorMallFee = res.data.IrisCreatorMallFee;

        }
        if (assetLevel == 2) {
          IrisPlatformMallFee = res.data.IrisPlatformMarketFee;
          IrisCreatorMallFee = res.data.IrisCreatorMarketFee;

        }
        console.log(IrisPlatformMallFee, IrisCreatorMallFee, IrisAddress);


        //链上付款
        let hash;
        let denom = preOrderRes.data.contractAddress;
        let toOwner = this.$store.state.did;
        let owner = preOrderRes.data.assetOwner;
        let tokenId = preOrderRes.data.tokenId;
        if (orderPrice != "0") {
         if(tokenId == null || denom == null || owner== null)
          {
              this.$toast("error", this.$t("buyFailed"));
              return;
          }
          let payResult = await orderPay(orderPrice, recipient, memo);
          hash = payResult.hash;
          console.log(hash);
          console.log(payResult);
        }
        // merchant
        if (orderPrice == "0" || hash != "") {
          if (orderPrice == "0") {
            hash = null;
          }
          let params = {
            callBack:
              `${process.env.VUE_APP_API_URL}` +
              "/api/3.0/orderInfo/wallet/pay.do?orderNo=" +
              orderNo,
            denom: denom,
            memo: memo,
            recipien: toOwner,
            shares: [
              { rate: IrisPlatformMallFee, received: IrisAddress },
              { rate: IrisCreatorMallFee, received: owner }
            ],
            hash: hash,
            tokenIds: [tokenId]
          };
		   params.deductionUseds=preOrderRes.data.deductionUseds
		  
          let BuyNow = await api.home.BuyNow(params);
          console.log("BuyNow");
          console.log(BuyNow);
        }

        //payDo
        let payDoParam = {
          "orderNo": orderNo
        };
        let payDo = await api.home.payDo(payDoParam);
        console.log(payDo);

        await this.sleep(500).then(async () => {   
           this.getData(this.saleMode);
        });
         //  await setTimeout(() => {
          
         //    // window.location.reload();
         //   this.getData();
         //  }, 4000);
          await this.sleep(1000).then(async () => {   
            if(hash){
                     let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                title:this.$t("transactionInprogressSuccess"),
              });
          }
            
          
        }
        });
       
         while (this.flag) {
             
              console.log("wwwwww");
            
              await this.sleep(5000).then(async () => {
                 //console.log(result.hash)
                let res = await quiryTx(hash);
                console.log("wxl -----  quiryTx");
                console.log(res);
                if (res.code == 0) {
                   	let t = this.$t("buySuccess")
					let reg=new RegExp('【N】','g')//g代表全部
					let regN=new RegExp('<br/>','g')//g代表全部
					let name = "【" + Ordername + "】"
               let title=t.replace(reg,name).replace(regN," ");
                           let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
                title:title,
              });
          }
               
                  this.flag = false;
                   await this.sleep(1000).then(async () => {   
                   window.location.reload();
                  });
                 
                } else if (res.code == -1) {
                  console.log("error");
                                    let isShowTip = localStorage.getItem("IRIS_isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
                     this.$mtip({
                    title:res.log,
              });
          }
               
              this.flag = false;
                } else {
                  this.flag = true;
                }
              });
            }

          //this.$refs.promptBox.show(this.$t("buySuccess"));
           //this.$toast("success", this.$t("buySuccess"));
          //this.getData();
           //window.location.reload();
            
         } catch (e) {
            this.$toast("error", this.$t("buyFailed"));
         }
      
      },

      groupScroll(e) {
        let ele = e.srcElement ? e.srcElement : e.target;
        // console.log(22232,ele.scrollTop, ele.offsetHeight, ele.scrollHeight);
        if ((ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 1)
          && this.pageNumber < this.totalPage) {
          this.getData(this.saleMode);
        }
      },
      groupMouseover() {
        this.$refs.group.focus();
      }
    }
  };
</script>

<style lang="scss" scoped>
   .contentleft {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 200px;
   }

   .line{
  width: 100%;
	height: 1px;
	border: solid 1px #e3e3e3;
}
.title {
  margin-top:22px;
  font-size: 25px;
  color: #270645;
  position: relative;
  .purchase{
    cursor: pointer;
    font-family: Helvetica;
	font-size: 25px;
	font-weight: bold;
	font-stretch: normal;
	letter-spacing: 0px;
	color: #766983;

  }
  
  .btn{
    	color: #270645;
      border-bottom: 3px solid  #270645;
      padding-bottom: 18px;
  }
  // .cantclick{
  //   pointer-events: none;
  // }
 
}

   .group {
      height: 73vh;
      max-height: 73vh;
      overflow-y: auto;
      padding: 0 5px;

      .item {
         height: 106px;
         background-image: linear-gradient(
                 #ffffff,
                 #ffffff),
         linear-gradient(
                 #6f58d9,
                 #6f58d9);
         background-blend-mode: normal,
         normal;
         box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
         border-radius: 5px;

         display: flex;
         align-items: center;
         // justify-content: space-between;
         margin: 20px 0;

         .img {
            width: 76px;
            height: 76px;
            border-radius: 5px;
            margin: 15px;
            object-fit: cover;
         }

         .name {
            width: 25%;
            display: flex;
            flex-direction: column;
            font-size: 11px;

            .s1 {
               font-size: 15px;
               font-weight: bold;
               line-height: 30px;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
               -o-text-overflow: ellipsis;
            }
         }

         .price {
            font-size: 14px;
            color: #270645;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            margin-top:20px;
            .s2 {
               position: relative;
               margin-top: 7px;
               margin-left: -10px;
               font-weight: bold;
            }
            .img{
                margin-top: 7px;
            }

            // .img {
            //    // content: " ";
            //    // background-image: url(../../assets/icons/chain_iris_w.png);
            //    // background-size: 22px;
            //    // width: 22px;
            //    // height: 22px;
            //    // left: -30px;
            //    // top: 0px;
            //    // position: absolute;
            //    // margin-right: 20px;
            //    // /assets/icons/chain_iris_w.png
            //    // position: absolute;
            // }
         }

         .time {
            font-size: 14px;
            color: #270645;
            display: flex;
            flex-direction: column;
            flex: 1;

            .s2 {
               margin-top: 8px;
               min-width: 138px;
            }
         }

         .state {
            margin-right: 30px;

            .box {
               display:inline-flex;
               width: 115px;
               height: 36px;
               box-sizing: border-box;
               padding: 2px;
               background-image: linear-gradient(
                       0deg,
                       #d202fd 0%,
                       #a538fe 26%,
                       #776eff 51%,
                       #01facd 100%
               );
               border-radius: 20px;

               .s1 {
                  width: 100%;
                  height: 100%;
                  border: none;
                  border-radius: 20px;
                  background: #fff;
                 font-family:Helvetica;
                  font-size: 15px;
                  font-weight: bold;
                  font-stretch: normal;
                  letter-spacing: 0;
                  color: #270645;
               }
            }
            .s2 {
               display: inline-flex;
               justify-content: center;
               align-items: center;
               margin-left: 20px;
               width: 115px;
               height: 36px;
               background-image: linear-gradient(90deg,
                   #d300fd 0%,
                   #a439fe 26%,
                   #7471ff 52%,
                   #00fdcf 100%),
               linear-gradient(
                       #270645,
                       #270645);
               background-blend-mode: normal,
               normal;
               box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
               border-radius: 18px;
               color:#fff !important;
               font-weight: bold;
            }
         }
         .saleStates{
      text-align: left!important;
      .titles{
            font-size: 14px !important;
    color: #270645;
      }
   
    }
      }
   }
   .mobile {
  .group {
    .item {
      height: 200px;
      align-items: flex-start;
      position: relative;
      .name {
        margin-top: 15px;
        width:80% !important;
      }
      .price {
        position: absolute;
        top: 100px;
        left: 20px;
        padding-left: 0px;
        .d1 {
          position: absolute;
          left: 50px;
        }
      }
      .time {
        .s1 {
          display: none;
        }
        .s2 {
          position: absolute;
          top: 120px;
          left: 20px;
        }
      }
      .state {
        position: absolute;
        top: 155px;
        left: 20px;
        text-align: left !important;
      }
      .saleStates{
         position: absolute;
        top: 90px;
        right: 10px;
        text-align: left !important;
      }
    }
  }

  .contentleft {
      width: 190px !important;
  }
}
   
   
</style>
